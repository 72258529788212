import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { SalesStoreReportMonthQueryComponent } from "./SalesStoreReportMonthQueryComponent";

export class SalesStoreReportMonthComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            reportStartDate: null,
            reportEndDate: null,
            totalStoreMonthData: null,
            newStoreMonthData: null,
            usedStoreMonthData: null,
            totalStoreMonthSummary: null,
            newStoreMonthSummary: null,
            usedStoreMonthSummary: null,
            showAllColumn: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onToggleShowAllClick = this.onToggleShowAllClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Sales Store Report";

    componentDidMount() {
        try {
            console.devLog("SalesStoreReportMonthComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/SalesStoreReport2/Month", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("SalesStoreReportMonthComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getLastUpdatedDate(selectedMonth) {
        try {
            let inputData = { startDate: CommonService.formatServerDateTime(selectedMonth) };
            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportMonthComponent/getLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetLastUpdatedMonthReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/GetLastUpdatedMonthReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedMonth) {
        try {
            this.setState({
                isLoadingData: true,
                reportStartDate: moment(selectedMonth).startOf("month"),
                reportEndDate: moment(selectedMonth).endOf("month"),
            });
            this.getLastUpdatedDate(selectedMonth);
            let inputData = { startDate: CommonService.formatServerDateTime(selectedMonth) };
            this.props.applicationInsights.trackTrace({ message: "/SalesStoreReportMonthComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/SalesStoreReport2/GetMonthReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let ttlStoreMonthData = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] !== 99);
                    let ttlStoreMonthSummary = parsedResponse.data.filter(row => row['stat'] === 'NUT' && row['store_number'] === 99)[0];
                    let nwStoreMonthData = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] !== 99);
                    let nwStoreMonthSummary = parsedResponse.data.filter(row => row['stat'] === 'New' && row['store_number'] === 99)[0];
                    let usdStoreMonthData = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] !== 99);
                    let usdStoreMonthSummary = parsedResponse.data.filter(row => row['stat'] === 'Used' && row['store_number'] === 99)[0];
                    this.setState({
                        isLoadingData: false,
                        totalStoreMonthData: ttlStoreMonthData,
                        totalStoreMonthSummary: ttlStoreMonthSummary,
                        newStoreMonthData: nwStoreMonthData,
                        newStoreMonthSummary: nwStoreMonthSummary,
                        usedStoreMonthData: usdStoreMonthData,
                        usedStoreMonthSummary: usdStoreMonthSummary
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        totalStoreMonthData: null,
                        newStoreMonthData: null,
                        usedStoreMonthData: null,
                        totalStoreMonthSummary: null,
                        newStoreMonthSummary: null,
                        usedStoreMonthSummary: null
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/SalesStoreReport2/GetMonthReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyClick(event) {
        try {
            let tableText = this.copyTextToString("Total", this.state.totalStoreMonthData, this.state.totalStoreMonthSummary);
            tableText += this.copyTextToString("New", this.state.newStoreMonthData, this.state.newStoreMonthSummary);
            tableText += this.copyTextToString("Used", this.state.usedStoreMonthData, this.state.usedStoreMonthSummary);
            navigator.clipboard.writeText(tableText);
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("SalesStoreReportMonthComponent", "onCopyClick", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onToggleShowAllClick(event) {
        try {
            this.setState({
                showAllColumn: !this.state.showAllColumn,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    copyTextToString(title, monthData, summaryData) {
        let tableText = "\t" + title + " Sales\t\t\t\t\t\t\t\t\t" + title + " Sales Budget Tracking vs Performance\t\t\t\t" + title + " Sales Year Over Year Tracking vs Performance\r\n";
        tableText += "\tSTORE\tVOLUME\tFRONT PVR\tBACK PVR\tTOTAL PVR\tTOTAL GROSS\tVOLUME TRACK\tGROSS TRACK\t\tBUDGET\tVOL vs BUDGET(%)\tGROSS vs BUDGET(%)\t\tVOL vs LAST YEAR (%)\tGROSS vs LAST YEAR (%)\tFRONT PVR Y/Y\tBACK PVR Y/Y\r\n";

        monthData.forEach((row) => {
            tableText += row.store_number + "\t" + row.store_name + "\t" + row.volume + "\t" +
                CommonService.formatCurrency(row.front_pvr) + "\t" + CommonService.formatCurrency(row.back_pvr) + "\t" + CommonService.formatCurrency(row.total_pvr) + "\t" +
                CommonService.formatCurrency(row.total_gross) + "\t" + CommonService.formatNumber(row.volume_track) + "\t" + CommonService.formatCurrency(row.total_gross_track) + "\t\t" +
                row.volume_goal + "\t" + CommonService.formatSignedNumber(row.volume_track_goal_diff) + " (" + CommonService.formatSignedPercentage_1(row.volume_track_goal_pct_diff) + ")\t" +
                CommonService.formatSignedCurrency(row.total_gross_track_goal_diff) + " (" + CommonService.formatSignedPercentage_1(row.total_gross_track_goal_pct_diff) + ")\t\t" +
                CommonService.formatSignedNumber(row.yoy_volume_delta) + " (" + CommonService.formatSignedPercentage_1(row.yoy_volume_delta_pct) + ")\t" +
                CommonService.formatSignedCurrency(row.yoy_total_gross_delta) + " (" + CommonService.formatSignedPercentage_1(row.yoy_total_gross_delta_pct) + ")\t" +
                CommonService.formatSignedCurrency(row.yoy_front_pvr_delta) + "\t" + CommonService.formatSignedCurrency(row.yoy_back_pvr_delta) + "\r\n";
        });

        let row = summaryData;
        tableText += "\tDGDG TOTAL\t" + row.volume + "\t" +
            CommonService.formatCurrency(row.front_pvr) + "\t" + CommonService.formatCurrency(row.back_pvr) + "\t" + CommonService.formatCurrency(row.total_pvr) + "\t" +
            CommonService.formatCurrency(row.total_gross) + "\t" + CommonService.formatNumber(row.volume_track) + "\t" + CommonService.formatCurrency(row.total_gross_track) + "\t\t" +
            row.volume_goal + "\t" + CommonService.formatSignedNumber(row.volume_track_goal_diff) + " (" + CommonService.formatSignedPercentage_1(row.volume_track_goal_pct_diff) + ")\t" +
            CommonService.formatSignedCurrency(row.total_gross_track_goal_diff) + " (" + CommonService.formatPercentage(row.total_gross_track_goal_pct_diff) + ")\t\t" +
            CommonService.formatSignedNumber(row.yoy_volume_delta) + " (" + CommonService.formatSignedPercentage_1(row.yoy_volume_delta_pct) + ")\t" +
            CommonService.formatSignedCurrency(row.yoy_total_gross_delta) + " (" + CommonService.formatSignedPercentage_1(row.yoy_total_gross_delta_pct) + ")\t" +
            CommonService.formatSignedCurrency(row.yoy_front_pvr_delta) + "\t" + CommonService.formatSignedCurrency(row.yoy_back_pvr_delta) + "\r\n\r\n";

        return tableText;
    }

    getHtmlSummaryRow(title, data) {
        let htmlSummaryRow = null
        if (data) {
            htmlSummaryRow = <tr className="dgdg-table-v3-row font-weight-bold">
                <td colSpan="9" className="dgdg-sales-store-bg dgdg-no-border-top" />
                <td>
                    {title}
                </td>
                <td>
                    {data.volume_goal}
                </td>
                <td className={data.volume_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                    {CommonService.formatSignedNumber(data.volume_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.volume_track_goal_pct_diff)})</span>
                </td>
                {
                    this.state.showAllColumn
                        ? <Fragment>
                            <td>
                                {
                                    title === "NEW"
                                        ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_new_gross)
                                        : title === "USED"
                                            ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_used_gross)
                                            : ""
                                }
                            </td>
                            <td>
                                {
                                    title === "NEW"
                                        ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_new_pvr)
                                        : title === "USED"
                                            ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_used_pvr)
                                            : ""
                                }
                            </td>
                        </Fragment>
                        : null
                }
                <td className={data.total_gross_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                    {CommonService.formatSignedCurrency(data.total_gross_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.total_gross_track_goal_pct_diff)})</span>
                </td>
                <td className="dgdg-sales-store-bg dgdg-no-border-top" />
                <td style={{ backgroundColor: data.yoy_volume_delta_pct_color }}>
                    {CommonService.formatSignedNumber(data.yoy_volume_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_volume_delta_pct)})</span>
                </td>
                <td style={{ backgroundColor: data.yoy_total_gross_delta_pct_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_total_gross_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_total_gross_delta_pct)})</span>
                </td>
                <td style={{ backgroundColor: data.yoy_front_pvr_delta_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_front_pvr_delta)}
                </td>
                <td style={{ backgroundColor: data.yoy_back_pvr_delta_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_back_pvr_delta)}
                </td>
            </tr>;
        }

        return htmlSummaryRow;
    }

    getHtmlTotalHeader() {
        let htmlHeaderRows = [];

        htmlHeaderRows.push(<tr key="header-1" className="dgdg-sales-store-bg-title-foot">
            <td className="dgdg-font-grey dgdg-font-medium">Total Sales</td>
            <td colSpan="7" />
            <td className="dgdg-sales-store-bg" />
            <td colSpan={this.state.showAllColumn ? "6" : "4"} className="text-left dgdg-font-grey dgdg-font-medium">Total Budget Tracking</td>
            <td className="dgdg-sales-store-bg" />
            <td colSpan="4" className="text-left dgdg-font-grey dgdg-font-medium">Total Year Over Year Tracking</td>
        </tr>);

        htmlHeaderRows.push(<tr key="header-2" className="dgdg-table-v3-header">
            <td>STORE</td>
            <td>VOLUME</td>
            <td>FRONT PVR</td>
            <td>BACK PVR</td>
            <td>TOTAL PVR</td>
            <td>TOTAL GROSS</td>
            <td>VOLUME TRACK</td>
            <td className="dgdg-no-border-right">GROSS TRACK</td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td />
            <td>BUDGET</td>
            <td>VOL vs BUDGET (%)</td>
            {
                this.state.showAllColumn
                    ? <Fragment>
                        <td>3 AVG MONTH GROSS</td>
                        <td>3 MONTH PVR</td>
                    </Fragment>
                    : null
            }
            <td className="dgdg-no-border-right">
                GROSS vs BUDGET (%)
                {
                    this.state.showAllColumn
                        ? <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-left" />
                        </button>
                        : <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-right" />
                        </button>
                }
            </td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td>VOL vs LAST YEAR (%)</td>
            <td>GROSS vs LAST YEAR (%)</td>
            <td>FRONT PVR Y/Y</td>
            <td>BACK PVR Y/Y</td>
        </tr>);

        return htmlHeaderRows;
    }

    getHtmlNewHeader() {
        let htmlHeaderRows = [];

        htmlHeaderRows.push(<tr key="header-1" className="dgdg-sales-store-bg-title-foot">
            <td className="dgdg-font-grey dgdg-font-medium">New Sales</td>
            <td colSpan="7" />
            <td className="dgdg-sales-store-bg" />
            <td colSpan={this.state.showAllColumn ? "6" : "4"} className="text-left dgdg-font-grey dgdg-font-medium">New Budget Tracking</td>
            <td className="dgdg-sales-store-bg" />
            <td colSpan="4" className="text-left dgdg-font-grey dgdg-font-medium">New Year Over Year Tracking</td>
        </tr>);

        htmlHeaderRows.push(<tr key="header-2" className="dgdg-table-v3-header">
            <td>STORE</td>
            <td>VOLUME</td>
            <td>FRONT PVR</td>
            <td>BACK PVR</td>
            <td>TOTAL PVR</td>
            <td>TOTAL GROSS</td>
            <td>VOLUME TRACK</td>
            <td className="dgdg-no-border-right">GROSS TRACK</td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td />
            <td>BUDGET</td>
            <td>VOL vs BUDGET (%)</td>
            {
                this.state.showAllColumn
                    ? <Fragment>
                        <td>3 AVG MONTH GROSS</td>
                        <td>3 MONTH PVR</td>
                    </Fragment>
                    : null
            }
            <td className="dgdg-no-border-right">
                GROSS vs BUDGET (%)
                {
                    this.state.showAllColumn
                        ? <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-left" />
                        </button>
                        : <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-right" />
                        </button>
                }
            </td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td>VOL vs LAST YEAR (%)</td>
            <td>GROSS vs LAST YEAR (%)</td>
            <td>FRONT PVR Y/Y</td>
            <td>BACK PVR Y/Y</td>
        </tr>);

        return htmlHeaderRows;
    }

    getHtmlUsedHeader() {
        let htmlHeaderRows = [];

        htmlHeaderRows.push(<tr key="header-1" className="dgdg-sales-store-bg-title-foot">
            <td className="dgdg-font-grey dgdg-font-medium">Used Sales</td>
            <td colSpan="7" />
            <td className="dgdg-sales-store-bg" />
            <td colSpan={this.state.showAllColumn ? "6" : "4"} className="text-left dgdg-font-grey dgdg-font-medium">Used Budget Tracking</td>
            <td className="dgdg-sales-store-bg" />
            <td colSpan="4" className="text-left dgdg-font-grey dgdg-font-medium">Used Year Over Year Tracking</td>
        </tr>);

        htmlHeaderRows.push(<tr key="header-2" className="dgdg-table-v3-header">
            <td>STORE</td>
            <td>VOLUME</td>
            <td>FRONT PVR</td>
            <td>BACK PVR</td>
            <td>TOTAL PVR</td>
            <td>TOTAL GROSS</td>
            <td>VOLUME TRACK</td>
            <td className="dgdg-no-border-right">GROSS TRACK</td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td />
            <td>BUDGET</td>
            <td>VOL vs BUDGET (%)</td>
            {
                this.state.showAllColumn
                    ? <Fragment>
                        <td>3 MONTH AVG WHOLESALE GROSS</td>
                        <td>3 MONTH PVR</td>
                    </Fragment>
                    : null
            }
            <td className="dgdg-no-border-right">
                GROSS vs BUDGET (%)
                {
                    this.state.showAllColumn
                        ? <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-left" />
                        </button>
                        : <button className="btn btn-link float-right" onClick={(event) => this.onToggleShowAllClick(event)}>
                            <span className="far fa-chevron-double-right" />
                        </button>
                }
            </td>
            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
            <td>VOL vs LAST YEAR (%)</td>
            <td>GROSS vs LAST YEAR (%)</td>
            <td>FRONT PVR Y/Y</td>
            <td>BACK PVR Y/Y</td>
        </tr>);

        return htmlHeaderRows;
    }

    getHtmlRows(dataRows, type) {
        let htmlRows = [];
        if (dataRows) {
            dataRows.forEach((data, index) => {
                let nutRow = this.state.totalStoreMonthData.filter(totalDT => totalDT.store_number === data.store_number)[0];
                htmlRows.push(<tr key={"row" + index} className="dgdg-table-v3-row">
                    <td className="text-right">
                        {data.store_number + " | " + data.store_name}
                    </td>
                    <td className={data.is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {data.volume}
                    </td>
                    <td className={data.is_max_front_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.front_pvr)}
                    </td>
                    <td className={data.is_max_back_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.back_pvr)}
                    </td>
                    <td className={data.is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.total_pvr)}
                    </td>
                    <td className={data.is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.total_gross)}
                    </td>
                    <td className={data.is_max_volume_track === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatNumber(data.volume_track)}
                    </td>
                    <td className={data.is_max_total_gross_track === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                        {CommonService.formatCurrency(data.total_gross_track)}
                    </td>
                    <td className="dgdg-sales-store-bg dgdg-no-border-top" />
                    <td>
                        {data.store_code}
                    </td>
                    <td>
                        {data.volume_goal}
                    </td>
                    <td className={data.volume_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                        {CommonService.formatSignedNumber(data.volume_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.volume_track_goal_pct_diff)})</span>
                    </td>
                    {
                        this.state.showAllColumn
                            ? <Fragment>
                                <td>
                                    {
                                        type === "New"
                                            ? CommonService.formatCurrency(nutRow?.three_month_avg_new_gross)
                                            : type === "Used"
                                                ? CommonService.formatCurrency(nutRow?.three_month_avg_used_gross)
                                                : ""
                                    }
                                </td>
                                <td>
                                    {
                                        type === "New"
                                            ? CommonService.formatCurrency(nutRow?.three_month_avg_new_pvr)
                                            : type === "Used"
                                                ? CommonService.formatCurrency(nutRow?.three_month_avg_used_pvr)
                                                : ""
                                    }
                                </td>
                            </Fragment>
                            : null
                    }
                    <td className={data.total_gross_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                        {CommonService.formatSignedCurrency(data.total_gross_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.total_gross_track_goal_pct_diff)})</span>
                    </td>
                    <td className="dgdg-sales-store-bg dgdg-no-border-top" />
                    <td style={{ backgroundColor: data.yoy_volume_delta_pct_color }}>
                        {CommonService.formatSignedNumber(data.yoy_volume_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_volume_delta_pct)})</span>
                    </td>
                    <td style={{ backgroundColor: data.yoy_total_gross_delta_pct_color }}>
                        {CommonService.formatSignedCurrency(data.yoy_total_gross_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_total_gross_delta_pct)})</span>
                    </td>
                    <td style={{ backgroundColor: data.yoy_front_pvr_delta_color }}>
                        {CommonService.formatSignedCurrency(data.yoy_front_pvr_delta)}
                    </td>
                    <td style={{ backgroundColor: data.yoy_back_pvr_delta_color }}>
                        {CommonService.formatSignedCurrency(data.yoy_back_pvr_delta)}
                    </td>
                </tr>);
            });
        }

        return htmlRows;
    }

    getHtmlFooter(data, type) {
        let htmlFooterRow = null;
        if (data) {
            htmlFooterRow = <tr className="dgdg-sales-store-bg-title-foot font-weight-bold">
                <td className="text-right">
                    DGDG TOTAL
                </td>
                <td className={data.is_max_volume === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {data.volume}
                </td>
                <td className={data.is_max_front_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.front_pvr)}
                </td>
                <td className={data.is_max_back_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.back_pvr)}
                </td>
                <td className={data.is_max_total_pvr === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.total_pvr)}
                </td>
                <td className={data.is_max_total_gross === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.total_gross)}
                </td>
                <td className={data.is_max_volume_track === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatNumber(data.volume_track)}
                </td>
                <td className={data.is_max_total_gross_track === 1 ? " dgdg-sales-store-bg-flag-green" : ""}>
                    {CommonService.formatCurrency(data.total_gross_track)}
                </td>
                <td className="dgdg-sales-store-bg" />
                <td>
                    DGDG
                </td>
                <td>
                    {data.volume_goal}
                </td>
                <td className={data.volume_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                    {CommonService.formatSignedNumber(data.volume_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.volume_track_goal_pct_diff)})</span>
                </td>
                {
                    this.state.showAllColumn
                        ? <Fragment>
                            <td>
                                {
                                    type === "New"
                                        ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_new_gross)
                                        : type === "Used"
                                            ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_used_gross)
                                            : ""
                                }
                            </td>
                            <td>
                                {
                                    type === "New"
                                        ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_new_pvr)
                                        : type === "Used"
                                            ? CommonService.formatCurrency(this.state.totalStoreMonthSummary?.three_month_avg_used_pvr)
                                            : ""
                                }
                            </td>
                        </Fragment>
                        : null
                }
                <td className={data.total_gross_goal_highlight === 1 ? "dgdg-sales-store-red-highlight" : "dgdg-sales-store-green-highlight"}>
                    {CommonService.formatSignedCurrency(data.total_gross_track_goal_diff)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.total_gross_track_goal_pct_diff)})</span>
                </td>
                <td className="dgdg-sales-store-bg" />
                <td style={{ backgroundColor: data.yoy_volume_delta_pct_color }}>
                    {CommonService.formatSignedNumber(data.yoy_volume_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_volume_delta_pct)})</span>
                </td>
                <td style={{ backgroundColor: data.yoy_total_gross_delta_pct_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_total_gross_delta)} <span className="dgdg-sales-store-font-gray">({CommonService.formatSignedPercentage_1(data.yoy_total_gross_delta_pct)})</span>
                </td>
                <td style={{ backgroundColor: data.yoy_front_pvr_delta_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_front_pvr_delta)}
                </td>
                <td style={{ backgroundColor: data.yoy_back_pvr_delta_color }}>
                    {CommonService.formatSignedCurrency(data.yoy_back_pvr_delta)}
                </td>
            </tr>;
        }

        return htmlFooterRow;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <SalesStoreReportMonthQueryComponent
                applicationInsights={this.props.applicationInsights}
                months={this.props.months}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
                onCopyClick={this.onCopyClick}
            />
            <div className="dgdg-sales-store dgdg-site-scrollable-content">
                <table className="dgdg-table-v3 text-center">
                    <tbody>
                        <tr className="dgdg-sales-store-bg-row">
                            <td className="text-right dgdg-sales-store-bg-row-alt dgdg-font-blue">REPORT DATE</td>
                            <td colSpan="3">
                                {
                                    this.state.reportStartDate
                                        ? CommonService.formatDate(this.state.reportStartDate) + " - " + CommonService.formatDate(this.state.reportEndDate)
                                        : null
                                }
                            </td>
                            <td colSpan={this.state.showAllColumn ? "8" : "6"} className="dgdg-sales-store-bg" />
                            <td colSpan="3" className="dgdg-font-grey dgdg-font-medium">Budget Tracking Summary</td>
                            <td className="dgdg-sales-store-bg" />
                            <td colSpan="4" className="dgdg-font-grey dgdg-font-medium">Year Over Year Tracking Summary</td>
                        </tr>
                        <tr className="dgdg-table-v3-header">
                            <td colSpan="10" className="dgdg-sales-store-bg dgdg-no-border-right" />
                            <td>BUDGET</td>
                            <td>VOL vs BUDGET (%)</td>
                            {
                                this.state.showAllColumn
                                    ? <Fragment>
                                        <td>3 AVG MONTH GROSS</td>
                                        <td>3 MONTH PVR</td>
                                    </Fragment>
                                    : null
                            }
                            <td className="dgdg-no-border-right">GROSS vs BUDGET (%)</td>
                            <td className="dgdg-sales-store-bg dgdg-no-border-right" />
                            <td>VOL vs LAST YEAR (%)</td>
                            <td>GROSS vs LAST YEAR (%)</td>
                            <td>FRONT PVR Y/Y</td>
                            <td>BACK PVR Y/Y</td>
                        </tr>
                        {this.getHtmlSummaryRow("TOTAL", this.state.totalStoreMonthSummary)}
                        {this.getHtmlSummaryRow("NEW", this.state.newStoreMonthSummary)}
                        {this.getHtmlSummaryRow("USED", this.state.usedStoreMonthSummary)}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        {this.getHtmlTotalHeader()}
                        {this.getHtmlRows(this.state.totalStoreMonthData, "Total")}
                        <tr>
                            <td />
                        </tr>
                        {this.getHtmlFooter(this.state.totalStoreMonthSummary, "Total")}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        {this.getHtmlNewHeader()}
                        {this.getHtmlRows(this.state.newStoreMonthData, "New")}
                        <tr>
                            <td />
                        </tr>
                        {this.getHtmlFooter(this.state.newStoreMonthSummary, "New")}
                        <tr>
                            <td><div className="dgdg-card-gutter" /></td>
                        </tr>
                        {this.getHtmlUsedHeader()}
                        {this.getHtmlRows(this.state.usedStoreMonthData, "Used")}
                        <tr>
                            <td />
                        </tr>
                        {this.getHtmlFooter(this.state.usedStoreMonthSummary, "Used")}
                    </tbody>
                </table>
            </div>
        </div>;
    }
}
import React, { Fragment } from "react";

import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { MissingTitlesDetailQueryComponent } from "./MissingTitlesDetailQueryComponent";

export class MissingTitlesDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            showDialog: false,
            paintTable: false,
            selectedStore: null,
            selectedStatus: null,
            selectedRow: null,
            tableTitle: "",
            tableData: [],
            showCloseDialog: false,
            showOpenDialog: false,
            actionHelpText: "",
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

        this.onAddNotesColumnCallback = this.onAddNotesColumnCallback.bind(this);
        this.onToggleViewNotes = this.onToggleViewNotes.bind(this);
        this.onToggleAddNotes = this.onToggleAddNotes.bind(this);
        this.onNoteTextChange = this.onNoteTextChange.bind(this);
        this.onSaveNoteClick = this.onSaveNoteClick.bind(this);


        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Missing Titles"
    statusData = [
        { key: "Open", value: "Open" },
        { key: "Resolved", value: "Resolved" },
        { key: "Closed", value: "Closed" }
    ];

    legendText = <Fragment>
        <div className="d-inline-block dgdg-deal-issues-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-deal-issues-status-bg-resolved" />&nbsp;&nbsp;&nbsp;Resolved</div>
        <div className="d-inline-block dgdg-deal-issues-legend-container"><div className="d-inline dgdg-import-wizard-legend dgdg-deal-issues-status-bg-closed" />&nbsp;&nbsp;&nbsp;Closed</div>
    </Fragment>;

    componentDidMount() {
        try {
            console.devLog("MissingTitlesDetailComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/MissingTitles/Detail", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("MissingTitlesDetailComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(selectedStore) {
        try {
            this.setState({
                selectedStore: selectedStore
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore, selectedStatus, id) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore,
                selectedStatus: selectedStatus,
            });
            let inputData = {
                id: id,
                storeNumber: selectedStore === "All Stores" ? null : selectedStore.store_number,
                status: selectedStatus && selectedStatus.key !== "All" ? selectedStatus.key : null
            };
            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse;
                    let actionHelpText = this.columnText(tableData[0]);
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: selectedStore.display_name,
                        actionHelpText: actionHelpText,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        tableTitle: "",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/onGetReportClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                stockNumber: rowData.stock_number,
                status: selectedItem,
            };

            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/SaveStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.last_updated_by = this.props.remoteUpn;
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.onGetReportClick(this.state.selectedStore, this.state.selectedStatus);
                        this.setState({ paintTable: false });
                    });

                    this.props.onShowAlert("success", { message: "Missing Title Stock # " + rowData.stock_number + " Status saved" }, 4000);
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/SaveStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddNotesColumnCallback(rowData) {
        let notes = this.getNotes(rowData.note);

        return <Fragment>
            <button key="btnViewNotes" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewNotes(event, rowData)}>{notes.length / 2} Notes
                {
                    rowData.showViewNotes
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : rowData.showViewNotes === undefined
                            ? <span className="far fa-sort-up dgdg-icon" >{this.onToggleViewNotes(null, rowData)}</span>
                            : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddNote" type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddNotes(event, rowData)}>Add Notes
                {
                    rowData.showAddNote
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewNotes
                    ? notes
                    : null
            }
            {
                rowData.showAddNote
                    ? <div key="frmAddNote" className="form-inline">
                        <DGDGFormTextAreaComponent id={"notes_" + rowData.stock_number} value={rowData["notes_" + rowData.stock_number]} rows="3" onChange={this.onNoteTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveNoteClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.notes_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewNotes(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewNotes = rowData.showViewNotes ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddNotes(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddNote = rowData.showAddNote ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({
                paintTable: false
            }, () => {
                if (rowData.showAddNote) {
                    let id = "#notes_" + rowData.stock_number;
                    $(id).focus();
                }
            });
        });
    }

    onNoteTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData["notes_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveNoteClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.notes_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                id: rowData.id,
                notes: rowData["notes_" + rowData.stock_number].replace(/\n|\r/g, " "),
            };
            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onSaveNoteClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/SaveNotes", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.note = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["notes_" + rowData.stock_number] + (rowData.note ? '\r' + rowData.note : "");
                    rowData["notes_" + rowData.stock_number] = "";
                    rowData.notes_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.notes_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/onSaveNoteClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comment);

        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : rowData.showViewComments === undefined
                            ? <span className="far fa-sort-up dgdg-icon" >{this.onToggleViewComments(null, rowData)}</span>
                            : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div key="frmAddComment" className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.stock_number} value={rowData["comments_" + rowData.stock_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({
                paintTable: false
            }, () => {
                if (rowData.showAddComment) {
                    let id = "#comments_" + rowData.stock_number;
                    $(id).focus();
                }
            });
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData["comments_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event, issueId) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false,
                addTableData: [],
                addTableTitle: "",
                addPaintTable: true
            }, () => {
                if (issueId) {
                    window.location = "/MissingTitles/Detail/All/All/All/" + issueId;
                }
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddMissingTitlesClick(event) {
        try {
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                stockNumber: rowData.stock_number,
                comments: rowData["comments_" + rowData.stock_number].replace(/\n|\r/g, " "),
            };
            this.props.applicationInsights.trackTrace({ message: "/MissingTitles/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/MissingTitles/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comment = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.stock_number] + (rowData.comment ? '\r' + rowData.comment : "");
                    rowData["comments_" + rowData.stock_number] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/MissingTitles/onSaveCommentClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("MissingTitles", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getNotes(notes) {
        let htmlContent = [];
        if (notes !== null) {
            let noteParts = [];
            notes.split('\r').forEach(function (value) {
                noteParts.push(value.split(': '));
            });
            noteParts.forEach((notePart, noteIndex) => {
                if (notePart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="note_part1" style={{ "color": "blue" }}>
                                {(notePart[0] !== "" ? notePart[0] + ": " : null)}
                            </span>
                            <span key="note_part2">
                                {(notePart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br key={"note_br_" + noteIndex} />);
                }
            });
        }

        return htmlContent;
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments?.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart, commentIndex) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br key={"comment_br_" + commentIndex} />);
                }
            });
        }

        return htmlContent;
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Open":
                rowData.rowCssName = "dgdg-deal-issues-status-bg-open";
                break;
            case "Resolved":
                rowData.rowCssName = "dgdg-deal-issues-status-bg-resolved";
                break;
            default:
                if (rowData.status) {
                    rowData.rowCssName = "dgdg-deal-issues-status-bg-default";
                }
                else {
                    rowData.rowCssName = null;
                }
        }
    }

    columnText() {
        return <Fragment>
            <span> <i className="fas fa-edit" /> : Edit<br /></span>
            <span> <i className="fas fa-lock" /> : Close, <i className="fas fa-lock-open" /> : Open </span>
        </Fragment>
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Close Deal Issue"
                bodyText={"Do you want to close Deal Number: " + this.state.selectedRow?.deal_number}
                showConfirmation={this.state.showCloseDialog}
                onOkClick={this.onCloseOkClick}
                onCancelClick={this.onCloseCancelClick}
            />

            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Open Deal Issue"
                bodyText={"Do you want to open Deal Number: " + this.state.selectedRow?.deal_number}
                showConfirmation={this.state.showOpenDialog}
                onOkClick={this.onOpenOkClick}
                onCancelClick={this.onOpenCancelClick}
            />
            <MissingTitlesDetailQueryComponent {...this.props}
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onStoreClick={this.onStoreClick}
                onGetReportClick={this.onGetReportClick}
                onAddMissingTitlesClick={this.onAddMissingTitlesClick}
            />

            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} stickyHeader legendText={this.legendText}
                    paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" data={["Open", "Closed", "Resolved"]} disabledColumn="is_status_disabled" headerCssName="dgdg-deal-issues-col-status" onItemClick={this.onStatusChange} showSpinner />
                        <DGDGTableColumnComponent headerText="Missing Title" dataColumn="missing_title" filterColumn="missing_title" sortColumn="missing_title" />
                        <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" />
                        <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" filterColumn="stock_number" sortColumn="stock_number" />
                        <DGDGTableColumnComponent headerText="Make" dataColumn="make" filterColumn="make" sortColumn="make" />
                        <DGDGTableColumnComponent headerText="Year" dataColumn="year" filterColumn="year" sortColumn="year" />
                        <DGDGTableColumnComponent headerText="Model" dataColumn="model" filterColumn="model" sortColumn="model" />
                        <DGDGTableColumnComponent headerText="Stocking Date" dataColumn="stocking_date" sortColumn="stocking_date" filterColumn="stocking_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Age" dataColumn="age" filterColumn="age" sortColumn="age" />
                        <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" filterColumn="deal_number" sortColumn="deal_number" />
                        <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="dateTime" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent cssName="dgdg-no-border-top">
                        <DGDGTableColumnComponent colSpan="6" />
                        {/* <DGDGTableColumnComponent colSpan="6" dataColumnCallback={this.onAddNotesColumnCallback} /> */}
                        <DGDGTableColumnComponent colSpan="7" dataColumnCallback={this.onAddCommentColumnCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { ServiceManagerPayrollQueryComponent } from "./ServiceManagerPayrollQueryComponent";
import { ServiceManagerPayrollFinancialDetailComponent } from "./ServiceManagerPayrollFinancialDetailComponent";

export class ServiceManagerPayrollSecondaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: null,
            isLoadingData: false,
            tableTitle: "",
            subStoresTableTitle: "",
            showFinancialDetail: false,
            financialDetailTitle: "",
            financialDetailTableData: [],
            financialDetailPaintTable: false,
            blobName: "",
            isDeveloper: false
        };

        this.onGetPayrollClick = this.onGetPayrollClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onFinancialDetailCancelClick = this.onFinancialDetailCancelClick.bind(this);
        this.onServiceFinancialDetailCellClick = this.onServiceFinancialDetailCellClick.bind(this);
        this.onPartsFinancialDetailCellClick = this.onPartsFinancialDetailCellClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("ServiceManagerPayrollSecondaryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/ServiceManagerPayroll", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            //this.getControllerPolicy();
            CommonService.clientAzureStorageLog("ServiceManagerPayrollSecondaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Service & Parts Manager Payroll";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "ServiceManagerPayrollSecondaryComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/ServiceManagerPayroll/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "ServiceManagerPayrollSecondaryComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/ServiceManagerPayroll/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetPayrollClick(selectedMonth) {
        try {
            if (selectedMonth !== null) {
                this.props.onGetPayrollClick(selectedMonth);
                this.setState({
                    selectedMonth: selectedMonth,
                    isLoadingData: true,
                    tableTitle: "Service & Parts Stores Secondary - " + CommonService.formatMonthYear(moment(selectedMonth)),
                    subStoresTableTitle: "Service & Parts Secondary Sub Stores - " + CommonService.formatMonthYear(moment(selectedMonth))
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("ServiceManagerPayrollSecondaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceFinancialDetailCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                category: "ServiceCommGross"
            };
            fetch("/ServiceManagerPayroll/GetServiceStoreMonthFinancialDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let amount = rowData.service_commissionable_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.service_commissionable_gross) + "</span>" : CommonService.formatCurrency_2(rowData.service_commissionable_gross);
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_name + " - Service Comm Gross - " + amount,
                        financialDetailTableData: CommonService.addOrdinalColumn(parsedResponse.details),
                        showFinancialDetail: true,
                        financialDetailPaintTable: true,
                        blobName: parsedResponse.blobName + " (Blob Count: 1)",
                        isDeveloper: parsedResponse.isDeveloper
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: "",
                        financialDetailTableData: [],
                        showFinancialDetail: true,
                        financialDetailPaintTable: true
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onFinancialDetailCancelClick(event) {
        this.setState({
            financialDetailTableData: [],
            showFinancialDetail: false,
        });
    }

    onPartsFinancialDetailCellClick(event, rowData) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: rowData.store_number,
                month: CommonService.formatServerDateTime(this.state.selectedMonth),
                category: "PartsCommGross"
            };
            fetch("/ServiceManagerPayroll/GetServiceStoreMonthFinancialDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let amount = rowData.parts_commissionable_gross < 0 ? "<span class='dgdg-font-red'>" + CommonService.formatCurrency_2(rowData.parts_commissionable_gross) + "</span>" : CommonService.formatCurrency_2(rowData.parts_commissionable_gross);
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: CommonService.formatMonthYear(moment(this.state.selectedMonth)) + " - " + rowData.store_name + " - Parts Comm Gross - " + amount,
                        financialDetailTableData: CommonService.addOrdinalColumn(parsedResponse.details),
                        showFinancialDetail: true,
                        financialDetailPaintTable: true,
                        blobName: parsedResponse.blobName + " (Blob Count: 1)",
                        isDeveloper: parsedResponse.isDeveloper
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        financialDetailTitle: "",
                        financialDetailTableData: [],
                        showFinancialDetail: true,
                        financialDetailPaintTable: true
                    }, () => {
                        this.setState({ financialDetailPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <ServiceManagerPayrollFinancialDetailComponent
                showDialog={this.state.showFinancialDetail}
                tableData={this.state.financialDetailTableData}
                tableTitle={this.state.financialDetailTitle}
                paintTable={this.state.financialDetailPaintTable}
                blobName={this.state.blobName}
                isDeveloper={this.state.isDeveloper}
                applicationInsights={this.props.applicationInsights}
                onCancelClick={this.onFinancialDetailCancelClick}
                onShowAlert={this.props.onShowAlert}
                onCopyTableCallBack={this.onCopyTableCallBack}
            />
            <ServiceManagerPayrollQueryComponent
                isLoadingData={this.props.isLoadingData}
                months={this.props.months}
                applicationInsights={this.props.applicationInsights}
                onGetPayrollClick={this.onGetPayrollClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="dgdg-service-manager-secondary-payroll">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} cardCssName="dgdg-service-manager-payroll-gap" tableData={this.props.tableData} headerText={this.state.tableTitle} paintTable={this.props.paintTable} stickyHeader showFooter
                        tablePageSize={CommonService.tablePageSize} copyTableConfig={{
                            "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
                            onCopyTableCallBack: this.onCopyTableCallBack
                        }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" footerText="DGDG" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Actual Hours Worked" dataColumn="actual_hours" sortColumn="actual_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Available Hours" dataColumn="available_hours" sortColumn="available_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Available Hours Sold %" dataColumn="available_hours_pct" sortColumn="available_hours_pct" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="CP Labor Hours" dataColumn="cp_labor_hours" sortColumn="cp_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP BEV Labor Hours" dataColumn="cp_bev_labor_hours" sortColumn="cp_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP ICE Labor Hours" dataColumn="cp_ice_labor_hours" sortColumn="cp_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP Labor Hours" dataColumn="wp_labor_hours" sortColumn="wp_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP BEV Labor Hours" dataColumn="wp_bev_labor_hours" sortColumn="wp_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP ICE Labor Hours" dataColumn="wp_ice_labor_hours" sortColumn="wp_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP Labor Hours" dataColumn="ip_labor_hours" sortColumn="ip_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP BEV Labor Hours" dataColumn="ip_bev_labor_hours" sortColumn="ip_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP ICE Labor Hours" dataColumn="ip_ice_labor_hours" sortColumn="ip_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Care/Care Plus Hours" dataColumn="carefree_hours" sortColumn="carefree_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Quick Lube Hours" dataColumn="quick_lube_hours" sortColumn="quick_lube_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="PDI Labor Hours" dataColumn="pdi_labor_hours" sortColumn="pdi_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Quick Lube Gross" dataColumn="express_change_labor_gross" sortColumn="express_change_labor_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Closed ROs" dataColumn="ro_count" sortColumn="ro_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="VIN Without PDI Count" dataColumn="vin_without_pdi_count" sortColumn="vin_without_pdi_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP ELR" dataColumn="customer_effective_labor_rate" sortColumn="customer_effective_labor_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP ELR" dataColumn="wp_elr" sortColumn="wp_elr" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP ELR" dataColumn="ip_elr" sortColumn="ip_elr" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Overall ELR" dataColumn="overall_effective_labor_rate" sortColumn="overall_effective_labor_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP Door Rate" dataColumn="cp_door_rate" sortColumn="cp_door_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Warr Rate" dataColumn="warr_rate" sortColumn="warr_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Int Rate" dataColumn="int_rate" sortColumn="int_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Xtime LOF Rate" dataColumn="xtime_lof_rate" sortColumn="xtime_lof_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Xtime Maint. Rate" dataColumn="xtime_maint_rate" sortColumn="xtime_maint_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Menu Usage %" dataColumn="menu_penetration" sortColumn="menu_penetration" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Same Day Selling %" dataColumn="sds_pct" sortColumn="sds_pct" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Ext/US Warranty Gross" dataColumn="us_warranty_labor_gross" sortColumn="us_warranty_labor_gross" filterColumn="us_warranty_labor_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP Labor & Parts Gross" dataColumn="cp_labor_parts_gross" sortColumn="cp_labor_parts_gross" filterColumn="cp_labor_parts_gross" dataType="money_2" columnHelp="CP Labor & Parts Gross excluding GO&G" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Service Comm Gross" dataColumn="service_commissionable_gross" sortColumn="service_commissionable_gross" dataType="money" columnHelp="Service Commissionable Gross: OEM Service Gross excluding GO&G, including Loaner income/expense items" footerCssName="text-right" footerFunction="sum" onCellClick={this.onServiceFinancialDetailCellClick} />
                            <DGDGTableColumnComponent headerText="Parts Comm Gross" dataColumn="parts_commissionable_gross" sortColumn="parts_commissionable_gross" dataType="money" columnHelp="Parts Commissionable Gross: OEM Parts Gross  including GO&G, excluding Inventory Adjustments" footerCssName="text-right" footerFunction="sum" onCellClick={this.onPartsFinancialDetailCellClick} />
                            <DGDGTableColumnComponent headerText="Fixed Comm Gross" dataColumn="fixed_commissionable_gross" sortColumn="fixed_commissionable_gross" dataType="money" footerCssName="text-right" footerFunction="sum" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.props.subStoresTableData} headerText={this.state.subStoresTableTitle} paintTable={this.props.paintTable}
                        tablePageSize={CommonService.tablePageSize} copyTableConfig={{
                            "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
                            onCopyTableCallBack: this.onCopyTableCallBack
                        }} showFooter
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" cssName="text-nowrap" footerText="DGDG" />
                            <DGDGTableColumnComponent headerText="Sub Store" dataColumn="sub_store" sortColumn="sub_store" filterColumn="sub_store" cssName="text-nowrap" />
                            <DGDGTableColumnComponent headerText="Actual Hours Worked" dataColumn="actual_hours" sortColumn="actual_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Available Hours" dataColumn="available_hours" sortColumn="available_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Available Hours Sold %" dataColumn="available_hours_pct" sortColumn="available_hours_pct" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="CP Labor Hours" dataColumn="cp_labor_hours" sortColumn="cp_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP BEV Labor Hours" dataColumn="cp_bev_labor_hours" sortColumn="cp_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP ICE Labor Hours" dataColumn="cp_ice_labor_hours" sortColumn="cp_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP Labor Hours" dataColumn="wp_labor_hours" sortColumn="wp_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP BEV Labor Hours" dataColumn="wp_bev_labor_hours" sortColumn="wp_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP ICE Labor Hours" dataColumn="wp_ice_labor_hours" sortColumn="wp_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP Labor Hours" dataColumn="ip_labor_hours" sortColumn="ip_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP BEV Labor Hours" dataColumn="ip_bev_labor_hours" sortColumn="ip_bev_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP ICE Labor Hours" dataColumn="ip_ice_labor_hours" sortColumn="ip_ice_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Care/Care Plus Hours" dataColumn="carefree_hours" sortColumn="carefree_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Quick Lube Hours" dataColumn="quick_lube_hours" sortColumn="quick_lube_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="PDI Labor Hours" dataColumn="pdi_labor_hours" sortColumn="pdi_labor_hours" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Quick Lube Gross" dataColumn="express_change_labor_gross" sortColumn="express_change_labor_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Closed ROs" dataColumn="ro_count" sortColumn="ro_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="VIN Without PDI Count" dataColumn="vin_without_pdi_count" sortColumn="vin_without_pdi_count" dataType="formatted_number" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP ELR" dataColumn="customer_effective_labor_rate" sortColumn="customer_effective_labor_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="WP ELR" dataColumn="wp_elr" sortColumn="wp_elr" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="IP ELR" dataColumn="ip_elr" sortColumn="ip_elr" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Overall ELR" dataColumn="overall_effective_labor_rate" sortColumn="overall_effective_labor_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP Door Rate" dataColumn="cp_door_rate" sortColumn="cp_door_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Warr Rate" dataColumn="warr_rate" sortColumn="warr_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Int Rate" dataColumn="int_rate" sortColumn="int_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Xtime LOF Rate" dataColumn="xtime_lof_rate" sortColumn="xtime_lof_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Xtime Maint. Rate" dataColumn="xtime_maint_rate" sortColumn="xtime_maint_rate" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Menu Usage %" dataColumn="menu_penetration" sortColumn="menu_penetration" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Same Day Selling %" dataColumn="sds_pct" sortColumn="sds_pct" dataType="percent_2" footerCssName="text-right" footerFunction="average" />
                            <DGDGTableColumnComponent headerText="Ext/US Warranty Gross" dataColumn="us_warranty_labor_gross" sortColumn="us_warranty_labor_gross" filterColumn="us_warranty_labor_gross" dataType="money_2" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="CP Labor & Parts Gross" dataColumn="cp_labor_parts_gross" sortColumn="cp_labor_parts_gross" filterColumn="cp_labor_parts_gross" dataType="money_2" columnHelp="CP Labor & Parts Gross excluding GO&G" footerCssName="text-right" footerFunction="sum" />
                            <DGDGTableColumnComponent headerText="Service Comm Gross" dataColumn="service_commissionable_gross" sortColumn="service_commissionable_gross" dataType="money" columnHelp="Service Commissionable Gross: OEM Service Gross excluding GO&G, including Loaner income/expense items" footerCssName="text-right" footerFunction="sum" onCellClick={this.onServiceFinancialDetailCellClick} />
                            <DGDGTableColumnComponent headerText="Parts Comm Gross" dataColumn="parts_commissionable_gross" sortColumn="parts_commissionable_gross" dataType="money" columnHelp="Parts Commissionable Gross: OEM Parts Gross  including GO&G, excluding Inventory Adjustments" footerCssName="text-right" footerFunction="sum" onCellClick={this.onPartsFinancialDetailCellClick} />
                            <DGDGTableColumnComponent headerText="Fixed Comm Gross" dataColumn="fixed_commissionable_gross" sortColumn="fixed_commissionable_gross" dataType="money" footerCssName="text-right" footerFunction="sum" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </div>;
    }
}
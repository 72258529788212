import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { FIDealReportQueryComponent } from "./FIDealReportQueryComponent";

export class FIDealReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownloading: false,
            tableTitle: "",
            paintTable: false
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FIDealReportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FIDealReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("FIDealReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "F&I Deal Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FIDealReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIDealReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "FIDealReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIDealReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(startDate, endDate) {
        try {
            this.setState({ isLoadingData: true });
            var inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString()),
                endDate: CommonService.formatServerDateTime(endDate.toString())
            };

            this.props.applicationInsights.trackTrace({ message: "/FIDealReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIDealReport/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: CommonService.formatDate(moment(startDate)) + " - " +
                            CommonService.formatDate(moment(endDate)),
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIDealReport/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(startDate, endDate) {
        try {
            this.setState({ isDownloading: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/FIDealReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FIDealReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownloading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownloading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FIDealReport/Download", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FIDealReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <FIDealReportQueryComponent
                    isLoadingData={this.state.isLoadingData} isDownloading={this.state.isDownloading}
                    onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                    onShowAlert={this.props.onShowAlert}
                    applicationInsights={this.props.applicationInsights}
                />
                <div className="dgdg-site-scrollable-content">
                    <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                        tablePageSize={CommonService.tablePageSize}
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 45, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63], onCopyTableCallBack: this.onCopyTableCallBack }}
                    >
                        <DGDGTableRowComponent>
                            <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" />
                            <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                            <DGDGTableColumnComponent headerText="Contract Date" dataColumn="contract_date" sortColumn="contract_date" filterColumn="contract_date" dataType="date" />
                            <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                            <DGDGTableColumnComponent headerText="Customer First" dataColumn="customer_first" sortColumn="customer_first" filterColumn="customer_first" />
                            <DGDGTableColumnComponent headerText="Customer Middle" dataColumn="customer_middle" sortColumn="customer_middle" filterColumn="customer_middle" />
                            <DGDGTableColumnComponent headerText="Customer Last" dataColumn="customer_last" sortColumn="customer_last" filterColumn="customer_last" />
                            <DGDGTableColumnComponent headerText="Co-Buyer First Name" dataColumn="co_customer_first" sortColumn="co_customer_first" filterColumn="co_customer_first" />
                            <DGDGTableColumnComponent headerText="Co-Buyer Last Name" dataColumn="co_customer_last" sortColumn="co_customer_last" filterColumn="co_customer_last" />
                            <DGDGTableColumnComponent headerText="New Used Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                            <DGDGTableColumnComponent headerText="Sale Type" dataColumn="sale_type" sortColumn="retail_lease_pr" filterColumn="retail_lease_pr" />
                            <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                            <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" />
                            <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                            <DGDGTableColumnComponent headerText="Is New EV" dataType="bool" dataColumn="is_new_ev" sortColumn="is_new_ev" filterColumn="is_new_ev" />
                            <DGDGTableColumnComponent headerText="Vin" dataColumn="vin" sortColumn="vin" filterColumn="vin" />
                            <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                            <DGDGTableColumnComponent headerText="Status" dataColumn="fi_wip_status_code" sortColumn="fi_wip_status_code" filterColumn="fi_wip_status_code" />
                            <DGDGTableColumnComponent headerText="Vehicle Mileage" dataColumn="vehicle_mileage" sortColumn="vehicle_mileage" filterColumn="vehicle_mileage" dataType="number" />
                            <DGDGTableColumnComponent headerText="Sales Rep 1 Name" dataColumn="sales_rep_1_name" sortColumn="sales_rep_1_name" filterColumn="sales_rep_1_name" />
                            <DGDGTableColumnComponent headerText="Sales Rep 2 Name" dataColumn="sales_rep_2_name" sortColumn="sales_rep_2_name" filterColumn="sales_rep_2_name" />
                            <DGDGTableColumnComponent headerText="Sales Mgr Name" dataColumn="sales_mgr_name" sortColumn="sales_mgr_name" filterColumn="sales_mgr_name" />
                            <DGDGTableColumnComponent headerText="Finance Mgr Name" dataColumn="finance_mgr_name" sortColumn="finance_mgr_name" filterColumn="finance_mgr_name" />
                            <DGDGTableColumnComponent headerText="Finance Mgr Number" dataColumn="finance_mgr_number" sortColumn="finance_mgr_number" filterColumn="finance_mgr_number" />
                            <DGDGTableColumnComponent headerText="Adjustments" dataType="money" dataColumn="adjustments" sortColumn="adjustments" filterColumn="adjustments" />
                            <DGDGTableColumnComponent headerText="Bank Fee" dataType="money" dataColumn="bank_fee" sortColumn="bank_fee" filterColumn="bank_fee" />
                            <DGDGTableColumnComponent headerText="Rebate" dataType="money" dataColumn="rebate" sortColumn="rebate" filterColumn="rebate" />
                            <DGDGTableColumnComponent headerText="Finance Source" dataColumn="finance_source" sortColumn="finance_source" filterColumn="finance_source" />
                            <DGDGTableColumnComponent headerText="Buy Rate Apr" dataType="percent_2" dataColumn="buy_rate_apr" sortColumn="buy_rate_apr" filterColumn="buy_rate_apr" />
                            <DGDGTableColumnComponent headerText="Sell Rate Apr" dataType="percent_2" dataColumn="sell_rate_apr" sortColumn="sell_rate_apr" filterColumn="sell_rate_apr" />
                            <DGDGTableColumnComponent headerText="Lien Holder Name" dataColumn="lien_holder_name" sortColumn="lien_holder_name" filterColumn="lien_holder_name" />
                            <DGDGTableColumnComponent headerText="Customer Cash Down" dataType="money" dataColumn="customer_cash_down" sortColumn="customer_cash_down" filterColumn="customer_cash_down" />
                            <DGDGTableColumnComponent headerText="Loan Term" dataType="number_2" dataColumn="loan_term" sortColumn="loan_term" filterColumn="loan_term" />
                            <DGDGTableColumnComponent headerText="Pick Up Pay 1" dataType="money" dataColumn="pick_up_pay_1" sortColumn="pick_up_pay_1" filterColumn="pick_up_pay_1" />
                            <DGDGTableColumnComponent headerText="Pick Up Pay 2" dataType="money" dataColumn="pick_up_pay_2" sortColumn="pick_up_pay_2" filterColumn="pick_up_pay_2" />
                            <DGDGTableColumnComponent headerText="Pick Up Pay 3" dataType="money" dataColumn="pick_up_pay_3" sortColumn="pick_up_pay_3" filterColumn="pick_up_pay_3" />
                            <DGDGTableColumnComponent headerText="Total Down" dataType="money" dataColumn="total_down" sortColumn="total_down" filterColumn="total_down" />
                            <DGDGTableColumnComponent headerText="Front Gross" dataType="money" dataColumn="front_gross" sortColumn="front_gross" filterColumn="front_gross" />
                            <DGDGTableColumnComponent headerText="Back Gross" dataType="money" dataColumn="back_gross" sortColumn="back_gross" filterColumn="back_gross" />
                            <DGDGTableColumnComponent headerText="Reserve" dataType="money" dataColumn="reserve" sortColumn="reserve" filterColumn="reserve" />
                            <DGDGTableColumnComponent headerText="VSC Name" dataColumn="vsc_name" sortColumn="vsc_name" filterColumn="vsc_name" />
                            <DGDGTableColumnComponent headerText="VSC Income" dataColumn="vsc_income" sortColumn="vsc_income" filterColumn="vsc_income" dataType="money" />
                            <DGDGTableColumnComponent headerText="VSC Sale Amount" dataType="money" dataColumn="vsc_sale_amount" sortColumn="vsc_sale_amount" filterColumn="vsc_sale_amount" />
                            <DGDGTableColumnComponent headerText="Term Care Name" dataColumn="term_care_name" sortColumn="term_care_name" filterColumn="term_care_name" />
                            <DGDGTableColumnComponent headerText="Term Care Income" dataType="money" dataColumn="term_care_income" sortColumn="term_care_income" filterColumn="term_care_income" />
                            <DGDGTableColumnComponent headerText="Term Care Sale Amount" dataType="money" dataColumn="term_care_sale_amount" sortColumn="term_care_sale_amount" filterColumn="term_care_sale_amount" />
                            <DGDGTableColumnComponent headerText="Wear Care Income" dataType="money" dataColumn="wear_care_income" sortColumn="wear_care_income" filterColumn="wear_care_income" />
                            <DGDGTableColumnComponent headerText="Wear Care Sale Amount" dataType="money" dataColumn="wear_care_sale_amount" sortColumn="wear_care_sale_amount" filterColumn="wear_care_sale_amount" />
                            <DGDGTableColumnComponent headerText="Maintenance Income" dataType="money" dataColumn="maint_income" sortColumn="maint_income" filterColumn="maint_income" />
                            <DGDGTableColumnComponent headerText="Maintenance Sale Amount" dataType="money" dataColumn="maint_sale_amount" sortColumn="maint_sale_amount" filterColumn="maint_sale_amount" />
                            <DGDGTableColumnComponent headerText="GAP Income" dataType="money" dataColumn="gap_income" sortColumn="gap_income" filterColumn="gap_income" />
                            <DGDGTableColumnComponent headerText="GAP Sale Amount" dataType="money" dataColumn="gap_sale_amount" sortColumn="gap_sale_amount" filterColumn="gap_sale_amount" />
                            <DGDGTableColumnComponent headerText="Road Hazard Income" dataType="money" dataColumn="road_haz_income" sortColumn="road_haz_income" filterColumn="road_haz_income" />
                            <DGDGTableColumnComponent headerText="Road Hazard Sale Amount" dataType="money" dataColumn="road_haz_sale_amount" sortColumn="road_haz_sale_amount" filterColumn="road_haz_sale_amount" />
                            <DGDGTableColumnComponent headerText="Paint Income" dataType="money" dataColumn="paint_income" sortColumn="paint_income" filterColumn="paint_income" />
                            <DGDGTableColumnComponent headerText="Paint Sale Amount" dataType="money" dataColumn="paint_sale_amount" sortColumn="paint_sale_amount" filterColumn="paint_sale_amount" />
                            <DGDGTableColumnComponent headerText="Key Replace Income" dataType="money" dataColumn="key_replace_income" sortColumn="key_replace_income" filterColumn="key_replace_income" />
                            <DGDGTableColumnComponent headerText="Key Replace Sale Amount" dataType="money" dataColumn="key_replace_sale_amount" sortColumn="key_replace_sale_amount" filterColumn="key_replace_sale_amount" />
                            <DGDGTableColumnComponent headerText="Dent Ding Income" dataType="money" dataColumn="dent_ding_income" sortColumn="dent_ding_income" filterColumn="dent_ding_income" />
                            <DGDGTableColumnComponent headerText="Dent Ding Sale Amount" dataType="money" dataColumn="dent_ding_sale_amount" sortColumn="dent_ding_sale_amount" filterColumn="dent_ding_sale_amount" />
                            <DGDGTableColumnComponent headerText="Shadow Income" dataType="money" dataColumn="shadow_income" sortColumn="shadow_income" filterColumn="shadow_income" />
                            <DGDGTableColumnComponent headerText="Shadow Sale Amount" dataType="money" dataColumn="shadow_sale_amount" sortColumn="shadow_sale_amount" filterColumn="shadow_sale_amount" />
                            <DGDGTableColumnComponent headerText="Windshield Income" dataType="money" dataColumn="windshield_income" sortColumn="windshield_income" filterColumn="windshield_income" />
                            <DGDGTableColumnComponent headerText="Windshield Sale Amount" dataType="money" dataColumn="windshield_sale_amount" sortColumn="windshield_sale_amount" filterColumn="windshield_sale_amount" />
                            <DGDGTableColumnComponent headerText="Accessories Income" dataType="money" dataColumn="accessories_income" sortColumn="accessories_income" filterColumn="accessories_income" />
                            <DGDGTableColumnComponent headerText="Accessories Sale Amount" dataType="money" dataColumn="accessories_sale_amount" sortColumn="accessories_sale_amount" filterColumn="accessories_sale_amount" />
                        </DGDGTableRowComponent>
                    </DGDGTableV3Component>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}

import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { FSCommon } from "./FSCommon";
import { FinancialStatementValueCellComponent } from "./FinancialStatementValueCellComponent";

export class TotalExpensesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    rowNumber = 1;
    mTotalRetail = null;
    yTotalRetail = null;
    mGrossProfit = null;
    yGrossProfit = null;

    totalExpensesSellingExpenseRow(label, valueKey, showDetail, enableDetailAmountClick) {
        let momPVR = null, yoyPVR = null;

        if (this.props.fsDict) {
            momPVR = FSCommon.parseDecimal(this.props.fsDict, "1~M~" + valueKey) / this.mTotalRetail;
            yoyPVR = FSCommon.parseDecimal(this.props.fsDict, "1~Y~" + valueKey) / this.yTotalRetail;
        }

        return <tr>
            <td className="text-center font-weight-normal">{this.rowNumber++}</td>
            <td>{label}</td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + valueKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="number" mode={this.props.mode} value={momPVR} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + valueKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className="dgdg-fs-value">
                <FinancialStatementValueCellComponent applicationInsights={this.props.applicationInsights} dataType="number" mode={this.props.mode} value={yoyPVR} />
            </td>
        </tr>;
    }

    totalExpensesSellingExpenseRowByValue(rowCssName, labelCssName, label, month, ytd) {
        let momPVR = null, yoyPVR = null;

        if (this.props.fsDict) {
            momPVR = month / this.mTotalRetail;
            yoyPVR = ytd / this.yTotalRetail;
        }

        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal">{this.rowNumber++}</td>
            <td className={labelCssName}>{label}</td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={month} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="number" mode={this.props.mode} value={momPVR} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={ytd} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="number" mode={this.props.mode} value={yoyPVR} />
            </td>
        </tr>;
    }

    totalExpensesRow(rowCssName, labelCssName, label, valueKey, showDetail, enableDetailAmountClick) {
        let momPVR = null, yoyPVR = null;

        if (this.props.fsDict) {
            momPVR = FSCommon.parseDecimal(this.props.fsDict, "1~M~" + valueKey) / this.mGrossProfit;
            yoyPVR = FSCommon.parseDecimal(this.props.fsDict, "1~Y~" + valueKey) / this.yGrossProfit;
        }

        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal">{this.rowNumber++}</td>
            <td className={labelCssName}>{label}</td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~M~" + valueKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={momPVR} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey={"1~Y~" + valueKey} onFinancialStatementDetailClick={showDetail ? this.props.onFinancialStatementDetailClick : null} enableDetailAmountClick={enableDetailAmountClick} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={yoyPVR} />
            </td>
        </tr>;
    }

    totalExpensesRowByValue(rowCssName, labelCssName, label, month, ytd) {
        let momPVR = null, yoyPVR = null;

        if (this.props.fsDict) {
            momPVR = month / this.mGrossProfit;
            yoyPVR = ytd / this.yGrossProfit;
        }

        return <tr className={rowCssName}>
            <td className="text-center font-weight-normal">{this.rowNumber++}</td>
            <td className={labelCssName}>{label}</td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={month} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={momPVR} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={ytd} />
            </td>
            <td className={"dgdg-fs-value " + labelCssName}>
                <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={yoyPVR} />
            </td>
        </tr>;
    }

    render() {
        this.rowNumber = 1;
        let mTotalNewVehicleDept = null, yTotalNewVehicleDept = null;
        let mTotalUsedVehicleRetail = null, yTotalUsedVehicleRetail = null;
        let mTotalVariableSellingExpense = null, yTotalVariableSellingExpense = null;
        let mTotalPersonnelExpense = null, yTotalPersonnelExpense = null;
        let mTotalSemiFixedExpense = null, yTotalSemiFixedExpense = null;
        let netToSales = null, netToGross = null;
        let totalVehicleGross = null, totalFixedGross = null;

        if (this.props.fsDict) {
            mTotalNewVehicleDept = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Stat~New Car Sales~Count Total New Vehicle Dept");
            yTotalNewVehicleDept = FSCommon.parseDecimal(this.props.fsDict, "1~Y~New Stat~New Car Sales~Count Total New Vehicle Dept");

            mTotalUsedVehicleRetail = FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Stat~Used Car Sales~Count Total Used Vehicle Retail");
            yTotalUsedVehicleRetail = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Used Stat~Used Car Sales~Count Total Used Vehicle Retail");

            this.mTotalRetail = mTotalNewVehicleDept + mTotalUsedVehicleRetail;
            this.yTotalRetail = yTotalNewVehicleDept + yTotalUsedVehicleRetail;
            this.mGrossProfit = FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~All~Gross Profit");
            this.yGrossProfit = FSCommon.parseDecimal(this.props.fsDict, "1~Y~Income~All~Gross Profit");

            // Total Variable Selling Expense
            //"Advertising - Digital", 
            mTotalVariableSellingExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "All",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Advertising", "Advertising - Digital",
                    "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);
            yTotalVariableSellingExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "All",
                ["Compensation - Product Specialists", "F&I Compensation", "Floor Plan Interest", "Floor Plan (Allowances)", "Advertising", "Advertising - Digital",
                    "Advertising (Allowances)", "Guest Experience Adjustments", "P.D.I & Vehicle Maintenance Expense"]);

            // Total Personnel Expense
            mTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "All",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel",
                    "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);
            yTotalPersonnelExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "All",
                ["Salaries - Supervision", "Service Advisor Compensation", "Salaries - Clerical", "Other Salaries and Wages", "Absentee Wages - Productive Personnel",
                    "Taxes - Payroll", "Employee Benefits", "Workman's Compensation", "Pension Fund"]);

            // Total Semi Fixed Expense
            mTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "M", "Expenses", "All",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services",
                    "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense",
                    "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);
            yTotalSemiFixedExpense = FSCommon.sumByKey(this.props.fsDict, "Y", "Expenses", "All",
                ["Company Car Expense", "Office Supplies & Expenses", "Small Tools & Other Supplies", "Uniform & Laundry", "Contributions", "Outside Services",
                    "Travel and Entertainment", "Memberships, Dues and Publications", "Legal and Auditing Expense", "Telephone and FAX", "Training Expense",
                    "Data Processing Expense", "Invalid Claim Expense", "Freight & Express", "Miscellaneous"]);

            netToSales = FSCommon.parseDecimal(this.props.fsDict, "1~M~Net Income~All Recast~Operating Profit or Loss") / FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~All~Net Sales");
            netToGross = FSCommon.parseDecimal(this.props.fsDict, "1~M~Net Income~All Recast~Operating Profit or Loss") / FSCommon.parseDecimal(this.props.fsDict, "1~M~Income~All~Gross Profit");

            totalVehicleGross = FSCommon.parseDecimal(this.props.fsDict, "1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept") + FSCommon.parseDecimal(this.props.fsDict, "1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used & Wholesale");
            totalFixedGross = FSCommon.parseDecimal(this.props.fsDict, "1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service") + FSCommon.parseDecimal(this.props.fsDict, "1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts");
        }

        let storeNumber = this.props.selectedStore?.store_number;
        return <table className="dgdg-fs-table">
            <tbody>
                <tr className="text-center font-weight-bold">
                    <td style={{ "width": "35px" }}>LINE</td>
                    <td>NAME OF ACCOUNT</td>
                    <td>MONTH</td>
                    <td>MOM</td>
                    <td>YEAR TO DATE</td>
                    <td>YOY</td>
                </tr>
                <tr className="font-weight-bold dgdg-fs-heading-1-bg-color">
                    <td className="font-weight-normal text-center">A</td>
                    <td>SALES</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Income~All~Net Sales" />
                    </td>
                    <td />
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~Income~All~Net Sales" />
                    </td>
                    <td />
                </tr>
                <tr className="font-weight-bold dgdg-fs-heading-1-bg-color">
                    <td className="font-weight-normal text-center">B</td>
                    <td>TOTAL GROSS</td>
                    <td className="dgdg-fs-value">
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Income~All~Gross Profit" />
                    </td>
                    <td />
                    <td className={"dgdg-fs-value"}>
                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~Y~Income~All~Gross Profit" />
                    </td>
                    <td />
                </tr>
                <tr className="font-weight-bold dgdg-fs-heading-2-bg-color">
                    <td className="font-weight-normal text-center">{this.rowNumber++}</td>
                    <td colSpan="2">SELLING EXPENSE</td>
                    <td className="text-center">PVR</td>
                    <td />
                    <td className="text-center">PVR</td>
                </tr>
                {this.totalExpensesSellingExpenseRow("Compensation - Product Specialists", "Expenses~All~Compensation - Product Specialists", true, true)}
                {this.totalExpensesSellingExpenseRow("Compensation - Finance", "Expenses~All~F&I Compensation", true, true)}
                {this.totalExpensesSellingExpenseRow("Floor Plan Interest", "Expenses~All~Floor Plan Interest", true, true)}
                {this.totalExpensesSellingExpenseRow("Floor Plan (Allowances)", "Expenses~All~Floor Plan (Allowances)", true, true)}
                {this.totalExpensesSellingExpenseRow("Demo Expense", "Expenses~All~Demo Expense", true, true)}
                {this.totalExpensesSellingExpenseRow("Advertising", "Expenses~All~Advertising", true, true)}
                {this.totalExpensesSellingExpenseRow("Advertising-Digital", "Expenses~All~Advertising - Digital", true, true)}
                {this.totalExpensesSellingExpenseRow("Advertising-Other", "", true, true)}
                {this.totalExpensesSellingExpenseRow("Advertising (Allowances)", "Expenses~All~Advertising (Allowances)", true, true)}
                {this.totalExpensesSellingExpenseRow("Guest Experience Adjustments", "Expenses~All~Guest Experience Adjustments", true, true)}
                {this.totalExpensesSellingExpenseRow("P.D.I & Vehicle Maintenance Expense", "Expenses~All~P.D.I & Vehicle Maintenance Expense", true, true)}
                {this.totalExpensesSellingExpenseRowByValue("font-weight-bold", "dgdg-fs-heading-1-bg-color", "TOTAL SELLING EXPENSE", mTotalVariableSellingExpense, yTotalVariableSellingExpense)}
                <tr>
                    <td className="text-center">{this.rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-heading-2-bg-color font-weight-bold">EMPLOYMENT EXPENSES</td>
                    <td className="dgdg-fs-heading-2-bg-color font-weight-bold text-center">% of Oper. Income</td>
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color font-weight-bold text-center">% of Oper. Income</td>
                </tr>
                {this.totalExpensesRow("", "", "Salaries-Owners", "", true, true)}
                {this.totalExpensesRow("", "", "Salaries-Supervision", "Expenses~All~Salaries - Supervision", true, (storeNumber === 0 || storeNumber === 99 ? false : this.props.isInSURole))}
                {this.totalExpensesRow("", "", "Salaries-Service Advisors", "Expenses~All~Service Advisor Compensation", true, true)}
                {this.totalExpensesRow("", "", "Salaries-Clerical", "Expenses~All~Salaries - Clerical", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRow("", "", "Other Salaries and Wages", "Expenses~All~Other Salaries and Wages", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRow("", "", "Absentee Wages-Productive Personnel", "Expenses~All~Absentee Wages - Productive Personnel", true, true)}
                {this.totalExpensesRow("", "", "Taxes-Payroll", "Expenses~All~Taxes - Payroll", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRow("", "", "Employee Benefits", "Expenses~All~Employee Benefits", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRow("", "", "Workman's Compensation", "Expenses~All~Workman's Compensation", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRow("", "", "Pension Fund", "Expenses~All~Pension Fund", true, (storeNumber === 0 || storeNumber === 99 ? this.props.isInSURole : true))}
                {this.totalExpensesRowByValue("font-weight-bold", "dgdg-fs-heading-1-bg-color", "TOTAL PERSONNEL EXPENSE", mTotalPersonnelExpense, yTotalPersonnelExpense)}
                <tr>
                    <td className="text-center">{this.rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-heading-2-bg-color font-weight-bold">SEMI-FIXED EXPENSES</td>
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color" />
                </tr>
                {this.totalExpensesRow("", "", "Company Car Expense", "Expenses~All~Company Car Expense", true, true)}
                {this.totalExpensesRow("", "", "Office Supplies & Expenses", "Expenses~All~Office Supplies & Expenses", true, true)}
                {this.totalExpensesRow("", "", "Small Tools & Other Supplies", "Expenses~All~Small Tools & Other Supplies", true, true)}
                {this.totalExpensesRow("", "", "Uniform & Laundry", "Expenses~All~Uniform & Laundry", true, true)}
                {this.totalExpensesRow("", "", "Contributions", "Expenses~All~Contributions", true, true)}
                {this.totalExpensesRow("", "", "Outside Services", "Expenses~All~Outside Services", true, true)}
                {this.totalExpensesRow("", "", "Travel and Entertainment", "Expenses~All~Travel and Entertainment", true, true)}
                {this.totalExpensesRow("", "", "Memberships, Due and Publications", "Expenses~All~Memberships, Dues and Publications", true, true)}
                {this.totalExpensesRow("", "", "Legal Auditing Expense", "Expenses~All~Legal and Auditing Expense", true, true)}
                {this.totalExpensesRow("", "", "Telephone and Fax", "Expenses~All~Telephone and FAX", true, true)}
                {this.totalExpensesRow("", "", "Training Expense", "Expenses~All~Training Expense", true, true)}
                {this.totalExpensesRow("", "", "Data Processing Expense", "Expenses~All~Data Processing Expense", true, true)}
                {this.totalExpensesRow("", "", "Invalid Claim Expense", "Expenses~All~Invalid Claim Expense", true, true)}
                {this.totalExpensesRow("", "", "Freight & Express", "Expenses~All~Freight & Express", true, true)}
                {this.totalExpensesRow("", "", "Miscellaneous", "Expenses~All~Miscellaneous", true, true)}
                {this.totalExpensesRowByValue("font-weight-bold", "dgdg-fs-heading-1-bg-color", "TOTAL SEMI-FIXED EXPENSE", mTotalSemiFixedExpense, yTotalSemiFixedExpense)}
                <tr>
                    <td className="text-center">{this.rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-heading-2-bg-color font-weight-bold">FIXED EXPENSES</td>
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color" />
                    <td className="dgdg-fs-heading-2-bg-color" />
                </tr>
                {this.totalExpensesRow("", "", "Rent", "Expenses~All~Rent", true, true)}
                {this.totalExpensesRow("", "", "Amortization-Leaseholds", "Expenses~All~Amortization - Leaseholds", true, true)}
                {this.totalExpensesRow("", "", "Repairs & Maintenance-Real Estate", "Expenses~All~Repairs & Maintenance - Real Estate", true, true)}
                {this.totalExpensesRow("", "", "Depreciation-Buildings and Improvements", "Expenses~All~Depreciation - Buildings and Improvements", true, true)}
                {this.totalExpensesRow("", "", "Taxes-Real Estate", "Expenses~All~Taxes - Real Estate", true, true)}
                {this.totalExpensesRow("", "", "Insurance-Buildings and Improvements", "Expenses~All~Insurance - Buildings and Improvements", true, true)}
                {this.totalExpensesRow("", "", "Interest Expense", "Expenses~All~Interest Real Estate Mortgage", true, true)}
                {this.totalExpensesRow("", "", "Heat, Light, Power and Water", "Expenses~All~Heat, Light, Power and Water", true, true)}
                {this.totalExpensesRow("", "", "Insurance-Other", "Expenses~All~Insurance - Other", true, true)}
                {this.totalExpensesRow("", "", "Taxes-Other", "Expenses~All~Taxes - Other", true, true)}
                {this.totalExpensesRow("", "", "Repairs & Maintenance-Equipment", "Expenses~All~Repair & Maintenance - Equipment", true, true)}
                {this.totalExpensesRow("", "", "Depreciation-Equipment", "Expenses~All~Depreciation - Equipment", true, true)}
                {this.totalExpensesRow("", "", "Equipment Rental", "Expenses~All~Equipment Rental", true, true)}
                {this.totalExpensesRow("", "", "Parts Hub Fee", "Expenses~All~Parts Hub Fee", true, true)}
                {
                    this.props.fsDict && (this.props.fsDict["1~M~Expenses~All~DGDG Management Fixed Fee"] || this.props.fsDict["1~Y~Expenses~All~DGDG Management Fixed Fee"])
                        ? this.totalExpensesRow("", "", "DGDG Management Fee", "Expenses~All~DGDG Management Fixed Fee", false)
                        : this.totalExpensesRow("", "", "DGDG Management Fee", "DGDG Management~External Income & Expense~All", false)
                }
                {this.totalExpensesRow("font-weight-bold", "dgdg-fs-heading-1-bg-color", "TOTAL FIXED EXPENSE", "Expenses~All Recast~Total Fixed Expense")}
                <tr>
                    <td className="text-center">{this.rowNumber++}</td>
                    <td colSpan="2" className="dgdg-fs-bg-black" />
                    <td className="dgdg-fs-bg-black" />
                    <td className="dgdg-fs-bg-black" />
                    <td className="dgdg-fs-bg-black" />
                </tr>
                {this.totalExpensesRow("font-weight-bold", "dgdg-fs-heading-1-bg-color", "TOTAL OPERATING EXPENSE", "Expenses~All Recast~Total Operating Expense")}
                {this.totalExpensesRow("font-weight-bold", "dgdg-fs-heading-1-bg-color", "OPERATING NET PROFIT OR (LOSS)", "Net Income~All Recast~Operating Profit or Loss")}
                <tr>
                    <td colSpan="6" style={{ padding: "0px" }}>
                        <br />
                        <table className="float-left" style={{ width: "45%" }}>
                            <tbody>
                                <tr>
                                    <td className="dgdg-no-border-left text-center font-weight-bold">NET TO SALES</td>
                                    <td className="dgdg-fs-value text-center font-weight-bold">
                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={netToSales} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left text-center font-weight-bold">NET TO GROSS</td>
                                    <td className="dgdg-fs-value text-center font-weight-bold">
                                        <FinancialStatementValueCellComponent dataType="percent_2" mode={this.props.mode} value={netToGross} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left">&nbsp;</td>
                                    <td />
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left">&nbsp;</td>
                                    <td />
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left">&nbsp;</td>
                                    <td />
                                </tr>
                                <tr>
                                    <td className="dgdg-no-border-left">&nbsp;</td>
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                        <table className="float-right" style={{ width: "45%" }}>
                            <tbody>
                                <tr>
                                    <td>Total New Car Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~New Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total New Vehicle Dept" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Used Car Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Used Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Used & Wholesale" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Total Vehicle Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right font-weight-bold">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalVehicleGross} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-left dgdg-no-border-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Total Serivce Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Service Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Service" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Total Parts Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} fsDict={this.props.fsDict} valueKey="1~M~Parts Gross~Mgmt Fee & Other Add Ded Gross Recast~Gross Total Parts" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Total Fixed Gross</td>
                                    <td className="dgdg-fs-value dgdg-no-border-right font-weight-bold">
                                        <FinancialStatementValueCellComponent dataType="money" mode={this.props.mode} value={totalFixedGross} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="dgdg-no-border-left  dgdg-no-border-right">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className="dgdg-no-border-right" />
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className="dgdg-no-border-right" />
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}

import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { FISalesPenetrationReportQueryComponent } from "./FISalesPenetrationReportQueryComponent";

export class FISalesPenetrationReportRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isDownLoading: false,
            stores: null,
            salesPenetrationRepReport: [],
            salesPenetrationManagerReport: [],
            tableTitle: "",
            paintTable: false
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onScrollClick = this.onScrollClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FISalesPenetrationReportRouteComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FISalesPenetrationReport", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getStores();
            this.getControllerPolicy();
            CommonService.clientAzureStorageLog("FISalesPenetrationReportRouteComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Sales Penetration Report";

    getControllerPolicy() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/getControllerPolicy", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FISalesPenetrationReport/GetControllerPolicy")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    console.devLog("ACL: " + parsedResponse.acl.join(), "FISalesPenetrationReportRouteComponent", "getControllerPolicy");
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FISalesPenetrationReport/GetControllerPolicy", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(startDate, endDate, selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate.toString()),
                endDate: CommonService.formatServerDateTime(endDate.toString()),
                storeNumber: selectedStore.store_number
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FISalesPenetrationReport/GetFISalesPenetrationReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: (selectedStore === null ? "All Stores" : selectedStore.display_name) +
                            " - " + CommonService.formatDate(moment(startDate)) +
                            " - " + CommonService.formatDate(moment(endDate)),
                        salesPenetrationManagerReport: CommonService.addOrdinalColumn(parsedResponse.table),
                        salesPenetrationRepReport: CommonService.addOrdinalColumn(parsedResponse.table1),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        salesPenetrationManagerReport: [],
                        salesPenetrationRepReport: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FISalesPenetrationReport/GetFISalesPenetrationReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDownloadClick(startDate, endDate, selectedStore) {
        try {
            this.setState({ isDownLoading: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };

            this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/onDownloadClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FISalesPenetrationReport/Download", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response } else { throw response; } })
                .then(response => {
                    this.setState({ isDownLoading: false });
                    CommonService.downloadBlob(response);
                })
                .catch(notOKResponse => {
                    this.setState({ isDownLoading: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FISalesPenetrationReport/GetFISalesPenetrationReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", { message: "Failed to download" });
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getStores() {
        this.props.applicationInsights.trackTrace({ message: "/FILenderReportRouteComponent/getStores", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FISalesPenetrationReport/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FISalesPenetrationReport/GetStores", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FISalesPenetrationReportRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onScrollClick(event, selector) {
        try {
            CommonService.smoothScrollTo(".dgdg-fi-sales", selector);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGPageComponent bodyCssName="dgdg-flex dgdg-flex-column">
            <div className="dgdg-report-body dgdg-flex dgdg-flex-column dgdg-flex-item dgdg-site-content">
                <FISalesPenetrationReportQueryComponent
                    applicationInsights={this.props.applicationInsights}
                    stores={this.state.stores}
                    isLoadingData={this.state.isLoadingData} isDownLoading={this.state.isDownLoading}
                    onGetReportClick={this.onGetReportClick} onDownloadClick={this.onDownloadClick}
                    onShowAlert={this.props.onShowAlert}
                />
                <nav className="nav nav-pills dgdg-nav">
                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#SalesManager")}>Sales Manager</button>
                    <button type="button" className="btn btn-link nav-item nav-link dgdg-quick-link dgdg-quick-link-bg" onClick={(event) => this.onScrollClick(event, "#ProductSpecialist")}>Product Specialist</button>
                </nav>
                <div className="dgdg-fi-sales dgdg-site-scrollable-content">
                    <div id="SalesManager" className="dgdg-card-gutter">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Sales Manager - " + this.state.tableTitle} tableData={this.state.salesPenetrationRepReport} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" />
                                <DGDGTableColumnComponent headerText="Sales Manager Name" dataColumn="sales_mgr_name" sortColumn="sales_mgr_name" filterColumn="sales_mgr_name" />
                                <DGDGTableColumnComponent headerText="Sales Manager Number" dataColumn="sales_mgr_number" sortColumn="sales_mgr_number" filterColumn="sales_mgr_number" />
                                <DGDGTableColumnComponent headerText="Deal Involed" dataColumn="deal_involved" sortColumn="deal_involved" filterColumn="deal_involved" />
                                <DGDGTableColumnComponent headerText="Deal Credit" dataColumn="deal_credit" sortColumn="deal_credit" filterColumn="deal_credit" />
                                <DGDGTableColumnComponent headerText="Front PVR" dataColumn="front_gross_pvr" sortColumn="front_gross_pvr" filterColumn="front_gross_pvr" dataType="money" />
                                <DGDGTableColumnComponent headerText="Back PVR" dataColumn="back_gross_pvr" sortColumn="back_gross_pvr" filterColumn="back_gross_pvr" dataType="money" />
                                <DGDGTableColumnComponent headerText="Overall PPR" dataColumn="ppr" sortColumn="ppr" filterColumn="ppr" backgroundColor="ppr_color" dataType="number_3" />
                                <DGDGTableColumnComponent headerText="VSC % " dataColumn="warranty_penetration" sortColumn="warranty_penetration" filterColumn="warranty_penetration" backgroundColor="warranty_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Maint %" dataColumn="maint_penetration" sortColumn="maint_penetration" filterColumn="maint_penetration" backgroundColor="maint_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Gap %" dataColumn="gap_penetration" sortColumn="gap_penetration" filterColumn="gap_penetration" backgroundColor="gap_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Road Hazard %" dataColumn="road_hazard_penetration" sortColumn="road_hazard_penetration" filterColumn="road_hazard_penetration" backgroundColor="road_hazard_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Paint %" dataColumn="paint_penetration" sortColumn="paint_penetration" filterColumn="paint_penetration" backgroundColor="paint_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Shadow Mark %" dataColumn="shadow_mark_penetration" sortColumn="shadow_mark_penetration" filterColumn="shadow_mark_penetration" backgroundColor="shadow_mark_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Wear Care %" dataColumn="wear_care_penetration" sortColumn="wear_care_penetration" filterColumn="wear_care_penetration" backgroundColor="wear_care_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Dent Ding %" dataColumn="dent_ding_penetration" sortColumn="dent_ding_penetration" filterColumn="dent_ding_penetration" backgroundColor="dent_ding_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Key %" dataColumn="key_replace_penetration" sortColumn="key_replace_penetration" filterColumn="key_replace_penetration" backgroundColor="key_replace_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Windshield %" dataColumn="windshield_penetration" sortColumn="windshield_penetration" filterColumn="windshield_penetration" backgroundColor="windshield_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Other %" dataColumn="other_penetration" sortColumn="other_penetration" filterColumn="other_penetration" backgroundColor="other_penetration_color" dataType="percent" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                    <div id="ProductSpecialist" className="dgdg-card-gutter">
                        <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={"Product Specialist - " + this.state.tableTitle} tableData={this.state.salesPenetrationManagerReport} paintTable={this.state.paintTable}
                            copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], onCopyTableCallBack: this.onCopyTableCallBack }}
                        >
                            <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Store Name" cssName="text-nowrap" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" />
                                <DGDGTableColumnComponent headerText="Sales Rep Name" dataColumn="sales_rep_name" sortColumn="sales_rep_name" filterColumn="sales_rep_name" />
                                <DGDGTableColumnComponent headerText="Sales Rep Number" dataColumn="sales_rep_number" sortColumn="sales_rep_number" filterColumn="sales_rep_number" />
                                <DGDGTableColumnComponent headerText="Deal Involed" dataColumn="deal_involved" sortColumn="deal_involved" filterColumn="deal_involved" />
                                <DGDGTableColumnComponent headerText="Deal Credit" dataColumn="deal_credit" sortColumn="deal_credit" filterColumn="deal_credit" />
                                <DGDGTableColumnComponent headerText="Front PVR" dataColumn="front_gross_pvr" sortColumn="front_gross_pvr" filterColumn="front_gross_pvr" dataType="money" />
                                <DGDGTableColumnComponent headerText="Back PVR" dataColumn="back_gross_pvr" sortColumn="back_gross_pvr" filterColumn="back_gross_pvr" dataType="money" />
                                <DGDGTableColumnComponent headerText="Overall PPR" dataColumn="ppr" sortColumn="ppr" filterColumn="ppr" backgroundColor="ppr_color" dataType="number_3" />
                                <DGDGTableColumnComponent headerText="VSC % " dataColumn="warranty_penetration" sortColumn="warranty_penetration" filterColumn="warranty_penetration" backgroundColor="warranty_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Maint %" dataColumn="maint_penetration" sortColumn="maint_penetration" filterColumn="maint_penetration" backgroundColor="maint_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Gap %" dataColumn="gap_penetration" sortColumn="gap_penetration" filterColumn="gap_penetration" backgroundColor="gap_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Road Hazard %" dataColumn="road_hazard_penetration" sortColumn="road_hazard_penetration" filterColumn="road_hazard_penetration" backgroundColor="road_hazard_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Paint %" dataColumn="paint_penetration" sortColumn="paint_penetration" filterColumn="paint_penetration" backgroundColor="paint_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Shadow Mark %" dataColumn="shadow_mark_penetration" sortColumn="shadow_mark_penetration" filterColumn="shadow_mark_penetration" backgroundColor="shadow_mark_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Wear Care %" dataColumn="wear_care_penetration" sortColumn="wear_care_penetration" filterColumn="wear_care_penetration" backgroundColor="wear_care_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Dent Ding %" dataColumn="dent_ding_penetration" sortColumn="dent_ding_penetration" filterColumn="dent_ding_penetration" backgroundColor="dent_ding_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Key %" dataColumn="key_replace_penetration" sortColumn="key_replace_penetration" filterColumn="key_replace_penetration" backgroundColor="key_replace_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Windshield %" dataColumn="windshield_penetration" sortColumn="windshield_penetration" filterColumn="windshield_penetration" backgroundColor="windshield_penetration_color" dataType="percent" />
                                <DGDGTableColumnComponent headerText="Other %" dataColumn="other_penetration" sortColumn="other_penetration" filterColumn="other_penetration" backgroundColor="other_penetration_color" dataType="percent" />
                            </DGDGTableRowComponent>
                        </DGDGTableV3Component>
                    </div>
                </div>
            </div>
        </DGDGPageComponent>;
    }
}
